@import '../../style/themes/index';
@import '../../style/mixins/index';

@avatar-prefix-cls: ~'@{ant-prefix}-avatar';

.@{avatar-prefix-cls} {
  .reset-component;

  position: relative;
  display: inline-block;
  overflow: hidden;
  color: @avatar-color;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: @avatar-bg;

  &-image {
    background: transparent;
  }

  .avatar-size(@avatar-size-base, @avatar-font-size-base);

  &-lg {
    .avatar-size(@avatar-size-lg, @avatar-font-size-lg);
  }

  &-sm {
    .avatar-size(@avatar-size-sm, @avatar-font-size-sm);
  }

  &-square {
    border-radius: @avatar-border-radius;
  }

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.avatar-size(@size, @font-size) {
  width: @size;
  height: @size;
  line-height: @size;
  border-radius: 50%;

  &-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center;
  }

  &.@{avatar-prefix-cls}-icon {
    font-size: @font-size;
  }
}

@secondary-color: #FFA328;@tertiary-color: #ffffff;@second-menu-bg: #dae1ef;@gray: #c5c5c5;@dark-gray: #898989;@font-sizes: [object Object];@primary-color: #060641;@info-color: #1aa2c4;@success-color: #27ae60;@processing-color: #1aa2c4;@error-color: #c41a1a;@highlight-color: #c41a1a;@warning-color: #c4ab1a;@normal-color: #d9d9d9;@white: #ffffff;@black: #010102;@font-family: Montserrat, sans-serif;@border-color-base: #010102;@input-bg: transparent;@input-hover-border-color: #010102;@btn-primary-bg: #6d1c99;@primary6: #010102;@link-color: #010102;@tooltip-bg: #6d1c99;@menu-bg: #ffffff;@layout-body-background: #F1F1F1;@layout-header-background: #ffffff;@layout-footer-background: @layout-body-background;@layout-header-height: 72px;@layout-sider-background: @layout-header-background;@secondary-menu-color: #dae1ef;